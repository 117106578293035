.footer-component {
  /* Start: Brought forward from shared css */
  background: #212a3f;
  .logo {
    margin: 18px;
    text-indent: -999em;
    display: inline-block;
    width: 200px;
    height: 25px;
    background: url('/assets/switcheonetwork_logo.svg') no-repeat center;
  }
  /* End: Brought forward from shared css */

  /* https://github.com/ConjurTech/switcheo-site/blob/master/footer.css */
  section#cta {
    min-height: 320px;
    padding-top: 80px;
  }
  section#cta:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  section#cta a.switcheo-cta-btn {
    padding: 0 20px;
    font-size: 1.2em;
    margin: 0 auto;
    width: 200px;
    display: block;
    position: relative;
    z-index: 1;
    line-height: 50px;
    text-align: center;
    background: #88c773;
    border-radius: 20px;
    color: #fff;
  }
  section#cta h3{
    position: relative;
    text-align: center;
    margin-bottom: 20px;
  }
  footer{
    background: #181d2e;
    position: relative;
    padding: 10px 0 0 0;
  }
  footer .section-wrap{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 90%;
    flex-basis: content;
    flex-wrap: wrap;
    max-width: 1440px;
    padding: 50px 0 20px 0;
    margin: 0 auto;
    flex-grow: 10;
  }
  footer ul{
    margin: 0;
    padding: 0;
  }
  .footer-right-wrap{
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    order: 3;
    flex: 5;
  }
  .footer-right-wrap ul li{
    list-style: none;
  }
  .social{
    display: flex;
    flex-direction: column;
    order: 2;
    flex-grow: 1;
    margin-top: 40px;
  }
  footer .logo-wrap{
    display: flex;
    flex: 0 0 100%;
    order: 1;
    padding-bottom: 20px;
  }
  footer .footer-bottom{
    flex: 0 0 100%;
    flex-direction: row;
    order: 5;
    padding-top: 10px;
    margin-top: 50px;
  }
  footer .copyright{
    font-family: 'Roboto', san-serif;
    color: #808ba7;
    text-align: center;
  }
  .social ul{
    margin-right: 2.5px;
  }
  .social ul li{
    font-family: 'Roboto', san-serif;
    list-style: none;
    display: inline-block;
    margin-left: 20px;
  }
  .social ul li a{
    color: #fff;
    display: block;
    text-align:left;
    transition: all 0.2s;
    font-size: 1.5em;
  }
  .social ul li a:hover{
    color: #88c773;
    transition: all 0.3s;
    opacity: 1;
  }
  .hidden {
    display: none;
  }
  .sitemap{
    display: flex;
    flex-direction: column;
    order: 1;
    flex: 4;
    align-items: flex-start;
  }
  .sitemap.quicklinks{
    order: 2;
    flex: 8;
  }
  .sitemap h3, .social h3, .mini-text{
    font-size: 1.6em;
    color: #808ba7;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 0.08em;
  }
  .sitemap ul li{
    list-style: none;
    margin-bottom: 5px;
    text-align: left;
  }
  .sitemap ul li a{
    text-decoration: none;
    font-family: 'Roboto', san-serif;
    color: white;
    font-weight: 300;
    font-size: 1.7em;
    line-height: 1.3;
  }
  .sitemap ul li a:hover{
    color: #88c773;
    transition: 0.3s;
    text-decoration: none;
  }
  .sign-up-wrap form{
    position: relative;
  }
  input{
    padding: 12.5px 20px;
    background: none;
    border-radius: 30px;
    border: none;
    color: #fff;
  }
  textarea:focus{
    border: 1px solid #88c773;
    outline: none;
  }
  input[type='email']{
    border: 1px solid rgba(166, 179, 217, 0.1);
    background: rgba(166, 179, 217, 0.1);
    width: 370px;
    font-family: 'Poppins', san-serif;
  }
  input[type='email']:focus{
    outline: none;
    border: 1px solid #88c773;
    transition: all 0.3s;
  }
  input[type="email"]::placeholder {
    color: #808ba7;
  }
  .sg-response{
    font-weight: 300;
    font-size: 0.8em;
    color: #d82b5d;
  }
  .sign-up-wrap input[type=submit]{
      cursor: pointer;
      padding: 13.5px 20px;
      display: inline-block;
      border-radius: 30px;
      position: absolute;
      right:0px;
      transition: 0.3s;
  }
  .sign-up-wrap input[type=submit]:hover{
    color: #88c773;
    transition: 0.5s;
  }
  .sign-up-wrap input[type=checkbox]{
    cursor: pointer;
    opacity: 0;
    margin-right: 10px;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid rgba(166, 179, 217, 0.1);
    background: rgba(166, 179, 217, 0.1);
  }
  .sign-up-wrap input[type=checkbox]:checked ~ .checkmark {
    background-color: #808ba7;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .sign-up-wrap input[type=checkbox]:checked ~ .checkmark:after {
    display: block;
  }
  .sign-up-wrap .checkmark:after {
    left: 4.5px;
    top: 2.5px;
    width: 3px;
    height: 6px;
    border: solid #88c773;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .sign-up-wrap .sg-consent-text{
    font-size: 1.4em;
    margin-top: 10px;
    font-weight: 300;
    color: white;
    @media only screen and (max-width:979px){
      width: 86vw;
    }
  }
  .sign-up-wrap .sg-consent-text a{
    color: #88c773;
    text-decoration: none;
  }
  @media only screen and (min-width:320px) and (max-width:480px){
    .section-wrap{
      padding: 5px;
      position: unset;
      width: 100% !important;
    }
    .loading-wrap .button{
      margin-top: 10px;
    }
    footer{
      text-align: center;
    }
    footer .section-wrap{
      padding-top: 20px;
    }
    footer .logo-wrap{
      padding-bottom: 10px;
    }
    footer .logo-wrap .logo{
      display: block;
      margin: 0 auto;
      flex-grow: 5;
      margin-bottom: 0;
      margin-top: 20px;
    }
    footer .footer-bottom{
      flex-direction: column;
      text-align: center;
      align-items:center;
      justify-content:center;
    }
    .terms{
      order: 1 !important; 
    }
    .copyright{
      order: 2 !important;
    }
    input, .sign-up-wrap input[type=submit]{
      padding: 12.5px 20px;
    }
    input[type='email']{
      width: 300px;
      font-size: 0.9em;
      @media only screen and (max-width:979px){
        width: 82vw;
      }
    }
    .sign-up-wrap input[type=submit]{
      width: 80px;
      @media only screen and (max-width:979px){
        width: 52px;
      }
    }
    footer .sitemap{
      display: none;
    }
    footer .sign-up-wrap{
      float: none;
      margin: 0 auto;
    }
    footer .footer-right-wrap{
      float: none;
      margin: 0 auto;
      text-align: center;
    }
    footer .footer-right-wrap, footer .social{
      margin-top: 20px;
    }
    section#cta{
      padding: 0;
      padding-bottom: 60px;
      min-height: 0;
    }
    .checkmark {
      top: 57px;
      left: 30px;
      height: 10px;
      width: 10px;
    }
    .sign-up-wrap input[type=checkbox]{
      margin-right: 0px;
    }
    .sign-up-wrap .checkmark:after {
      left: 2px;
      top: 0px;
    }
  }
  @media only screen and (min-width:480px) and (max-width:768px){
    .section-wrap{
      padding: 5px;
    }
    footer{
      text-align: center;
    }
    footer .logo-wrap{
      padding-bottom: 10px;
    }
    footer .logo-wrap .logo{
      display: block;
      margin: 0 auto;
      flex-grow: 5;
      margin-bottom: 0;
      margin-top: 20px;
    }
    footer .footer-bottom{
      flex-direction: column;
      text-align: center;
      align-items:center;
      justify-content:center;
    }
    .terms{
      order: 1 !important;
      
    }
    .copyright{
      order: 2 !important;
    }
    input[type='email']{
      width: 300px;
      @media only screen and (max-width:979px){
        width: 82vw;
      }
    }
    footer .sitemap{
      display: none;
    }
    footer .sign-up-wrap{
      float: none;
      margin: 0 auto;
    }
    footer .footer-right-wrap{
      float: none;
      margin: 0 auto;
      text-align: center;
    }
    footer .footer-right-wrap, footer .social{
      margin-top: 20px;
    }
    footer .sign-up-wrap{
      float: none;
      margin: 0 auto;
    }
    footer .footer-right-wrap{
      float: none;
      margin: 0 auto;
    }
    footer .footer-right-wrap ul{
      margin: 0;
      margin-top: 20px;
      padding: 0;
    }
    footer .footer-right-wrap ul li{
      text-align: center;
    }
    section#cta{
      padding: 0;
      padding-bottom: 60px;
      min-height: 0;
    }
    input, .sign-up-wrap input[type=submit]{
      padding: 12.5px 20px;
    }
    .sign-up-wrap input[type=submit]{
      padding-right: 40px;
    }
    .checkmark {
      top: 57px;
      left: 30px;
      height: 10px;
      width: 10px;
    }
    .sign-up-wrap input[type=checkbox]{
      margin-right: 0px;
    }
    .sign-up-wrap .checkmark:after {
      left: 2px;
      top: 0px;
    }
  }
  @media only screen and (min-width:769px) and (max-width:1040px){
    .switcheo-cta-btn:after{
      display: none;
    }
    .section-wrap{
      position: unset;
    }
    .checkmark {
      height: 10px;
      width: 10px;
    }
    .sign-up-wrap .checkmark:after {
      left: 2px;
      top: 0px;
    }
    .sign-up-wrap input[type=checkbox]{
      margin-right: 5px;
    }
  }
  @media only screen and (min-width:1024px) and (max-width:1100px){
  
  }
}