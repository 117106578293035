.header-component {
  /* Start: Brought forward from shared css */
  background: #212a3f;
  a{
    text-decoration: none !important;
    color: #88c773;
  }
  /* End: Brought forward from shared css */

  /* https://github.com/ConjurTech/switcheo-site/blob/master/header.css */
  #nav-menu{
    width: 30px;
    height: 30px;
    position: fixed;
    top: 20px;
    right: 10px;
    cursor: pointer;
    z-index: 9999;
    display: none;
  }
  #nav-menu span{
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    width: 20px;
    border-bottom: 1px solid #fff;
    transition: 0.3s;
  }
  #nav-menu span:nth-child(2){
    top: 10px;
  }
  #nav-menu.active span:nth-child(1){
    transform: rotate(45deg);
    right: -20px;
    top: 5px;
  }
  #nav-menu.active span:nth-child(2){
    transform: rotate(-45deg);
    right: -20px;
    top: 5px;
  }
  header{
    width: 100%;
    position: fixed;
    z-index: 9999;
    border-bottom: 2px solid rgba(166, 179, 217, 0.2);
    font-size: 1.6rem;
    line-height: 1;
    transition: all 1s;
    display: flex;
    @media only screen and (min-width:1440px){
      font-size: 1.5rem;
    }
    @media only screen and (max-width:989px){
      display: block;
    }
  }
  header.solid{
    background: rgba(24, 29, 46, 0.95);
    border-bottom: 2px solid transparent;
    transition: all 0.5s;
  }
  header.solid #nav-menu span{
    border-bottom: 1px solid rgba(166, 179, 217, 0.8);
  }
  header.solid nav ul li a{
    color: rgba(166, 179, 217, 0.8);
    transition: 0.5s;
  }
  header nav{
    position: absolute;
    top: 16px;
    right: 0px;
    transition: 0.3s;
  }
  header nav ul{
    margin: 0;
    padding: 0;
  }
  header nav ul li{
    display: inline-block;
    position: relative;
  }
  header nav ul li.dropdown{
    width: 130px;
    position: relative;
    height: 50px;
    cursor: pointer;
  }
  header nav ul li.dropdown:after{
    content: '';
    position: absolute;
    top: 5px;
    right: 15px;
    width: 10px;
    height: 10px;
    background: url('/assets/arrow_down.svg') no-repeat;
    opacity: 0.5;
  }
  header nav ul li a{
    color: #ffffff;
    padding: 12px 0px;
    margin: 0 20px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }
  header nav ul li a.active{
    color: #88c773;
    border-bottom: 2px solid #88c773;
  }
  header nav ul li a:hover{
    color: #88c773;
    transition: all 0.3s;
  }
  header nav ul li ul{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 33px;
    left: -4.5px;
    margin: 0;
    height: 50px;
  }
  header nav ul li ul li{
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    padding: 5px 15px;
    text-align: left;
    border: 1px solid rgba(166, 179, 217, 0.4);
    background: rgba(32, 43, 63, 0.8);
  }
  header nav ul li ul li.activecampaign:hover{
    background: rgba(255, 255, 255, 0.95);
    color: white;
    transition: 0.3s;
  }
  header nav ul li ul li a{
    padding: 0;
    display: block;
    width: 100%;
    padding: 5px 0;
  }
  header nav ul li ul li.activecampaign a{
    color: #88c773;
  }
  header nav ul li ul li.inactive a{
    color: #787878;
  }
  header nav ul li:hover ul, header nav ul li ul:hover{
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  .logo{
    margin: 11.5px 20px;
    text-indent: -999em;
    display: inline-block;
    width: 200px;
    height: 25px;
    background: url('/assets/switcheonetwork_logo.svg') no-repeat center;
    transition: all 0.3s;
  }
  .solid .logo{
    background: url('/assets/switcheonetwork_logo_b.svg') no-repeat left;
    transition: all 0.3s;
    background-size: 200px 25px;
    margin: 11.5px 20px;
    width: 25px;
    height: 25px;
    padding-right: 10px;
  }
  .switcheo-loader-wrap{
    background: #212a3f;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 9998;
  }
  .switcheo-loader-wrap.bye{
    display: none;
  }
  .hide {
    display: none;
  }
  .switcheo-loader {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    top: 45vh;
  }
  .switcheo-loader div {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #32374b;
    animation: switcheo-loader 1.2s linear infinite;
  }
  .switcheo-loader div:nth-child(1) {
    top: 5px;
    left: 5px;
    animation-delay: 0s;
  }
  .switcheo-loader div:nth-child(2) {
    top: 5px;
    left: 20px;
    animation-delay: -0.4s;
  }
  .switcheo-loader div:nth-child(3) {
    background: #f0f0f0;
    top: 5px;
    left: 35px;
    animation-delay: -0.4s;
  }
  .switcheo-loader div:nth-child(4) {
    top: 5px;
    left: 50px;
    animation-delay: -0.4s;
  }
  .switcheo-loader div:nth-child(5) {
    background: #89c973;
    top: 20px;
    left: 5px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(6) {
    background: #f0f0f0;
    top: 20px;
    left: 20px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(7) {
    background: #89c973;
    top: 20px;
    left: 35px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(8) {
    background: #89c973;
    top: 20px;
    left: 50px;
    animation-delay: -1.2s;
  }
  .switcheo-loader div:nth-child(9) {
    background: #f0f0f0;
    top: 35px;
    left: 5px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(10) {
    background: #f0f0f0;
    top: 35px;
    left: 20px;
    animation-delay: -0.4s;
  }
  .switcheo-loader div:nth-child(11) {
    background: #89c973;
    top: 35px;
    left: 35px;
    animation-delay: -1.2s;
  }
  .switcheo-loader div:nth-child(12) {
    background: #f0f0f0;
    top: 35px;
    left: 50px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(13) {
    top: 50px;
    left: 5px;
    animation-delay: -0.8s;
  }
  .switcheo-loader div:nth-child(14) {
    background: #89c973;
    top: 50px;
    left: 20px;
    animation-delay: -1.2s;
  }
  .switcheo-loader div:nth-child(15) {
    top: 50px;
    left: 35px;
    animation-delay: -0.4s;
  }
  .switcheo-loader div:nth-child(16) {
    top: 50px;
    left: 50px;
    animation-delay: -0.8s;
  }
  @keyframes switcheo-loader {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  @media only screen and (min-width:320px) and (max-width:480px){
    html, body{
      font-size: 12px;
    }
    header{
      background: none;
    }
    header nav ul li{
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      font-size: 1.2em;
    }
    header nav ul li ul, header nav ul li.dropdown{
      position: static;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
    header nav ul li ul li a{
      padding: 0 !important;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.9em;
    }
    header nav ul li ul li{
      display: block;
      width: auto;
      font-size: 1.2em;
      margin-top: 5px;
      font-size: 0.8em;
      background: none;
      border: none;
      text-align: center;
    }
    header nav ul li a.active{
      border: none;
    }
    header nav ul li.dropdown:after{
      display: none;
    }
    .switcheo-cta-btn:after{
      display: none;
    }
    #nav-menu{
      display: block;
    }
    header nav{
      visibility: hidden;
      opacity: 0;
      height: 100vh;
      width: 100%;
      top: 0px;
      right: 0px;
    }
    nav.active{
      visibility: visible;
      opacity: 1;
      position: fixed;
      background: rgba(33, 42, 63, 0.98);
      z-index: 999;
      padding-top: 5vh;
    }
    .loading-wrap .button{
      margin-top: 10px;
    }
  }
  @media only screen and (min-width:480px) and (max-width:768px){
    html, body{
      font-size: 13px;
    }
    header{
      background: none;
    }
    header nav ul li{
      display: block;
      margin: 0 auto;
      margin-top: 25px;
      text-align: center;
      font-size: 1em;
    }
    header nav ul li ul{
      position:static;
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
    header nav ul li ul, header nav ul li.dropdown{
      position: static;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
    header nav ul li.dropdown:after{
      display: none;
    }
    header nav ul li ul li a{
      padding: 0 !important;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.9em;
    }
    header nav ul li ul li{
      display: block;
      width: auto;
      font-size: 1.2em;
      margin-top: 5px;
      font-size: 0.8em;
      background: none;
      border: none;
      text-align: center;
    }
    header nav ul li ul li a{
      color: #a8a8a8;
    }
    header nav ul li a.active{
      border: none;
    }
    .switcheo-cta-btn:after{
      display: none;
    }
    header nav ul li a.switcheo-cta-btn{
      margin-top: 30px;
    }
    header nav ul li.community{
      margin-right: 0px;
    }
    #nav-menu{
      display: block;
    }
    header nav{
      visibility: hidden;
      opacity: 0;
      height: 100vh;
      width: 100%;
      top: 0px;
      right: 0px;
    }
    nav.active{
      visibility: visible;
      opacity: 1;
      position: fixed;
      background: rgba(33, 42, 63, 0.98);
      z-index: 999;
      padding-top: 3vh;
    }
  }
  @media only screen and (min-width:769px) and (max-width:1040px){
    html, body{
      font-size: 14px;
    }
    header{
      background: none;
    }
    header nav ul li{
      display: block;
      margin: 0 auto;
      margin-top: 50px;
      text-align: center;
      font-size: 1.2em;
    }
    header nav ul li ul{
      position:static;
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
    header nav ul li ul, header nav ul li.dropdown{
      position: static;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
    header nav ul li.dropdown:after{
      display: none;
    }
    header nav ul li ul li a{
      padding: 0 !important;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.9em;
    }
    header nav ul li ul li{
      display: block;
      width: auto;
      font-size: 1.2em;
      margin-top: 5px;
      font-size: 0.8em;
      background: none;
      border: none;
      text-align: center;
    }
    header nav ul li ul li a{
      color: #a8a8a8;
    }
    header nav ul li a.active{
      border: none;
    }
    .switcheo-cta-btn:after{
      display: none;
    }
    .logo{
      display: none;
    }
    #nav-menu{
      display: block;
    }
    header nav{
      visibility: hidden;
      opacity: 0;
      height: 100vh;
      width: 100%;
      top: 0px;
      right: 0px;
    }
    nav.active{
      visibility: visible;
      opacity: 1;
      position: fixed;
      background: rgba(33, 42, 63, 0.98);
      z-index: 999;
      padding-top: 10vh;
    }
    .logo{
      display: block;
    }
  }
  @media only screen and (min-width:1024px) and (max-width:1100px){
  
  }
  
}
